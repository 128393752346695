import { render, staticRenderFns } from "./newLocation.vue?vue&type=template&id=189c6998&scoped=true"
import script from "./newLocation.vue?vue&type=script&lang=js"
export * from "./newLocation.vue?vue&type=script&lang=js"
import style0 from "./newLocation.vue?vue&type=style&index=0&id=189c6998&prod&scoped=true&lang=css"
import style1 from "./newLocation.vue?vue&type=style&index=1&id=189c6998&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189c6998",
  null
  
)

export default component.exports