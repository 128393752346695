<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-4">
        <map-component
          mode="location"
        />
      </v-col>
      <v-col class="right-area ma-4">
        <v-row
          class="header"
          justify="space-around"
        >
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/location-info')"
          >
          <span class="location-info-title">&nbsp;&nbsp;&nbsp;위치 편집</span>
          <v-spacer />
        </v-row>
        <v-row class="content-area">
          <router-view />
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import MapComponent from '@/components/map/map.vue';
import EventBus from '@/main.js';
import DefaultHeader from "@/components/header/defaultHeader.vue";

  export default {
    name: "NewLocation",
    components: {
      DefaultHeader,
      DefaultModal,
      MapComponent
    },
    data() {
      return {
        showModal: false,
        modalState: {
          message: null,
          action: null
        },
        locations: [],
      }
    },
    created() {
      EventBus.$on('showRemoveLocationModal', name => {
        this.confirmlDeleteLocation(name);
      });
    },
    beforeDestroy() {
      EventBus.$off('showRemoveLocationModal');
    },
    methods: {
      confirmlDeleteLocation(name) {
        this.modalState.message = `'${name}' 위치를 삭제하시겠습니까?`;
        this.modalState.action = '삭제';
        this.showModal = true;
      },
      doAction() {
        EventBus.$emit('deleteLocation');
        this.closeModal();
      },
      closeModal() {
        this.modalState.message = null;
        this.modalState.action = null;
        this.showModal = false;
      },
    }
  }
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.left-right-container {
  height: 100vh;
}

.location-info-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 90%;
}


@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .location-info-title {
    font-size: 20px !important;
  }

}

</style>

<style>
.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 3vw;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > #input-10 {
  max-height: none !important;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}

</style>
